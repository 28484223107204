import {
  TranslateCacheSettings,
  TranslateCacheService,
} from "ngx-translate-cache";
import { TranslateService } from "@ngx-translate/core";

export function translateCacheFactory(
  translateService: TranslateService,
  translateCacheSettings: TranslateCacheSettings
) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
