import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { translateLoaderFactory } from "../translate-loader-factory";
import { translateCacheFactory } from "../translate-cache-factory";
import {
  TranslateCacheModule,
  TranslateCacheSettings,
  TranslateCacheService,
} from "ngx-translate-cache";

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings],
      },
      cacheMechanism: "Cookie",
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService
  ) {
    translateCacheService.init();
    translate.setDefaultLang("en");
    translate.addLangs(["en", "zh"]);
    const browserLang =
      translateCacheService.getCachedLanguage() || translate.getBrowserLang();
    translate.use(browserLang.match(/en | zh/) ? browserLang : "en");
  }
}
