<div class="header__inner">
  <div class="header__left">
    <img
      class="header__left__logo header__left__logo--light"
      src="assets/images/Milliken-logo-white.svg"
      alt="Milliken Logo"
      routerLink="/{{ language }}"
    />
    <img
      class="header__left__logo header__left__logo--dark"
      src="assets/images/Milliken-logo-black.svg"
      alt="Milliken Logo"
      routerLink="/{{ language }}"
    />
    <div class="header__left__slogan">
      DeltaMax&reg; {{ "header.header_slogan" | translate }}
    </div>
  </div>
  <div class="header__right">
    <nav
      class="header__right__nav"
      [ngClass]="{ 'header__right__nav--slidein': isMobileNavVisible }"
    >
      <ul>
        <li>
          <a
            routerLink="{{ language }}/savings-calculator"
            [routerLinkActive]="['active']"
            (click)="showMobileNavigation()"
            >{{ "header.value_calculator" | translate }}</a
          >
        </li>
        <li>
          <a
            routerLink="{{ language }}/performance-simulator"
            [routerLinkActive]="['active']"
            (click)="showMobileNavigation()"
            >{{ "header.performance_simulator" | translate }}</a
          >
        </li>
      </ul>
    </nav>
    <div
      class="header__right__burger"
      (click)="showMobileNavigation()"
      [ngClass]="{ 'header__right__burger--active': isMobileNavVisible }"
    >
      <div class="header__right__burger__line"></div>
      <div class="header__right__burger__line"></div>
      <div class="header__right__burger__line"></div>
    </div>
  </div>
</div>
