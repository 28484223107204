import { Component, HostListener } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "src";
  headerBlur: boolean = true;

  constructor(private router: Router) {
    router.events.subscribe((nav) => {
      if (nav instanceof NavigationEnd) {
        this.checkHeaderTransparency();
      }
    });
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    this.checkHeaderTransparency();
  }

  @HostListener("window:resize", ["$event"]) // for window scroll events
  onResize(event) {
    this.checkHeaderTransparency();
  }

  checkHeaderTransparency() {
    const heroElement = document.getElementsByClassName("hero")[0];
    const headerElement = document.getElementsByTagName("deltamax-header")[0];
    if (
      heroElement &&
      document.documentElement.scrollTop + headerElement.clientHeight <
        heroElement.clientHeight
    ) {
      this.headerBlur = true;
    } else {
      this.headerBlur = false;
    }
  }
}
