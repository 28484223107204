<div class="footer__top">
  <div class="footer__top__inner">
    <ul class="footer__top__list">
      <li class="footer__top__item">
        <a class="footer__top__link" href="/{{ language }}/privacy-policy">{{
          "footer.privacy_policy" | translate
        }}</a>
      </li>
      <li class="footer__top__item">
        <a class="footer__top__link" href="/{{ language }}/cookie-policy">{{
          "footer.cookie_policy" | translate
        }}</a>
      </li>
    </ul>
  </div>
</div>
<div class="footer__bottom">
  <div class="footer__bottom__inner">
    <div class="footer__bottom__links">
      <span
        >Contents Copyright <sup>©</sup> 2002 - 2021 Milliken &amp;
        Company.</span
      >
      <span
        >All Rights Reserved. "Milliken" is a registered trademark of Milliken
        &amp; Company.</span
      >
      <ul class="footer__bottom__list">
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsofuse/public"
            target="_blank"
            class="footer__bottom__link"
            >Terms of use</a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsandconditions/public"
            target="_blank"
            class="footer__bottom__link"
            >Terms and Conditions of Sale</a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsandconditionspurchase/public"
            target="_blank"
            class="footer__bottom__link"
            >Terms and Conditions of Purchase</a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/privacy/public"
            target="_blank"
            class="footer__bottom__link"
            >Milliken Website Privacy Policy</a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/trademark/public"
            target="_blank"
            class="footer__bottom__link"
            >Trademarks</a
          >
        </li>
      </ul>
    </div>
    <a class="footer__bottom__logo">
      <img
        src="assets/images/Milliken-logo-white.svg"
        alt="Milliken Logo"
        routerLink="/{{ language }}"
      />
    </a>
  </div>
</div>
