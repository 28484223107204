import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageGuard implements Resolve<string> {
  constructor(private translate: TranslateService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): string | Observable<string> | Promise<string> {
    if (
      route.params?.lang &&
      this.translate.getLangs().find((lang) => lang === route.params?.lang)
    ) {
      this.translate.use(route.params?.lang);
      this.translate.currentLang = route.params.lang;
      return route.params?.lang;
    } else {
      this.router.navigate([
        this.translate.currentLang ?? this.translate.getDefaultLang(),
      ]);
      return this.translate.currentLang ?? this.translate.getDefaultLang();
    }
  }
}
