import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { CommonModule } from "@angular/common";
import { CoreModule } from "./core/core.module";
import { LanguageGuard } from "./localization/language-guard";
import { I18nModule } from "./localization/i18n/i18n.module";
import { PrivacyPolicyComponent } from './policy/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './policy/cookie-policy/cookie-policy.component';

@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent, CookiePolicyComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    I18nModule,
  ],
  providers: [LanguageGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
